import { Link } from "gatsby"
import React from "react"

const Footer = () => (
  <footer
    style={{
      background: `rebeccapurple`,
      marginBottom: `1.45rem`,
    }}
  >
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        padding: `1.45rem 1.0875rem`,
      }}
    >
    <Link to="/">Home</Link> 
    <Link to="/ourservices/">Our Services</Link> 
    <Link to="/portfolio/">Portfolio</Link> 
    <Link to="/contact/">Contact</Link> 
    </div>
  </footer>
)

export default Footer
