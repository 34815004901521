import { Link } from "gatsby"
import React from "react"

const Navigation = () => (
    <div>
<Link to="/">Home</Link> 
<Link to="/ourservices/ourservices">Our Services</Link> 
<Link to="/portfolio/">Portfolio</Link> 
<Link to="/contact/">Contact</Link> 
    </div>
  )

  export default Navigation